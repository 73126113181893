@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

// global tidy-ui-components styles import
@import 'components/tidy-ripple.scss';

@import 'variables.scss';
@import 'tidy-ui-global.scss';

// global tidy-ui-components styles import
@import 'components/tidy-ripple.scss';

@import "@angular/material/prebuilt-themes/indigo-pink.css";

h1, h2, h3, h4, h5, h6 {
  color: var(--color-tidy_black);
  text-transform: uppercase;
}

iframe {
  height: 100%;
  width: 100%;
}

.toolbar {
  min-height: 4rem;
  position: relative;

  .toolbar-content {
    display: flex;
    position: relative;
  }
}

.phone-icon {
  width: 24px;
}

.italics {
  font-style: italic;
}

.toolbar[tidy_black] .toolbar-background {
  background: #000;
}

img + h2 {
  margin-top: 1rem;
}

h2 {
  span {
    font-size: 3rem;
  }
}

.button-fab {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.45);
}

.item {
  color: var(--color-text);
}

.addTodo-second-step {
  display: none;

  &.active {
    display: block;
  }
}

.tidy-cleaning-navbar {
  background-color: transparent;
  box-shadow: none;
  margin: 0;
  text-align: center;
  width: 100%;

  ion-card-content {
    p {
      color: var(--color-text);
      font-size: 1.6rem;
      text-align: center;

      &.paragraph-left{
        text-align:  left;
        padding: 20px 0 0 0;
        margin: 0;
      }

      + p {
        margin-bottom: 40px;
      }
    }
  }

  > h1 {
    color: var(--color-tidy_black);
    display: inline-block;
    font-size: 2.6rem;
    text-align: center;

    &:after {
      border-bottom: 1px solid var(--color-title_border);
      content: "";
      display: block;
      margin: 5px auto 0;
      width: 80%;
    }
  }

  > ion-item.item.item-block {
    background-color: transparent;
    padding-left: 0;
    text-align: center;

    .item-inner {
      margin-left: 0;
      padding-right: 0;

      ion-label {
        margin-right: 0;
      }

      p {
        color: var(--colors-text);
        font-size: 1.6rem;
        min-height: 16px;

        &:first-child {
          margin-bottom: 1rem;
        }

        button[clear] {
          font-size: 1.65rem;
          margin: 0;
          padding: 0 .5rem;
          text-decoration: underline;
          top: -5px;
        }
      }
    }
  }
}

.logo-white-header {
  align-self: center;
  flex: 0 1 auto;
  margin-left: 10px;
  position: relative;
  top: 1px;
  width: 32px;
}

ion-list {
  &.suggested-items {
    border-color: var(--color-default_border);
    border-width: 0 1px;
    border-style: solid;
    margin-bottom: 0;
    margin-top: 10px;

    > .item {
      &:first-child {
        border-top-color: var(--colors-default_border);
      }
      &:last-child {
        border-bottom-color: var(--colors-default_border);
      }
    }

    ion-item {
      background-color: white;
      padding-left: 0;

      .item-inner {
        padding-left: 8px;
      }

      ion-label {
        font-size: 1.25rem;
      }
    }
  }
}

form {
  .item {
    background-color: transparent;
    padding-left: 0;

    &.item-input {
      .item-inner {
        border-bottom: 0;
        padding-right: 0;
      }
    }

    &.item-select,
    &.item-datetime {
      .item-inner {
        border: 0;
        padding-right: 0;
      }
    }

    &.item-select {
      ion-select {
        padding: 9px 15px 8px 8px;
      }
    }

    &.item-datetime {
      ion-datetime {
        padding: 11px 15px 8px 8px;
      }
    }
  }

  p {
    font-size: 1.6rem;
    text-align: center;
  }

  .item-input::after {
    left: 1px;
    right: 1px;
  }

  ion-label[stacked] {
    font-size: 1.6rem;

    strong {
      color: var(--color-tidy_black);
    }
  }

  > ion-label {
    color: var(--color-caption);
  }

  ion-card {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  ion-datetime,
  ion-select,
  ion-input,
  ion-textarea {
    background: white;
    border: 1px solid var(--color-default_border);
    font-size: 1.4rem;
    margin: 10px 1px 0;
    padding: 2px 8px 2px 8px;
    width: calc(100% - 2px);
  }

  button[type=submit] {
    margin-top: 15px;
  }

  .todo-buttons {
    button {
      width: 30%;

      + button {
        margin-left: 2%;
        width: 65%;
      }
    }
  }
}

.label-ios[stacked],
.label-md[stacked] {
  font-size: 1.6rem;
}

p[danger] {
 background-color: var(--color-alert);
 color: var(--color-text);
 font-size: 1.6rem;
 padding: 10px;
}

// Radio & Checkbox buttons
.item {
  ion-radio {
    background-color: #FFF;
    border-radius: 50%;
    border: 1px solid var(--color-default_border);
    height: 25px;
    margin-right: 10px;
    text-align: center;
    width: 25px;

    &[item-left] {
      margin-left: 0;
      margin-right: 10px;
    }

    .radio-icon {
      width: 100%;

    }
  }

  &.item-radio-checked {
    ion-radio {
      background-color: var(--color-radio_checkbox_color);
      border-color: var(--color-radio_checkbox_color);
    }

    ion-label {
      color: var(--color-text);
    }

    .radio-checked {
      .radio-inner {
        border-color: white;
        border-right-width: 3px;
        border-bottom-width: 3px;
        height: 14px;
        left: 8px;
        top: 3px;
        width: 8px;
      }
    }
  }
}

.item-checkbox {
  ion-checkbox {
    .checkbox-icon {
      border-color: var(--color-default_border);
      border-radius: 4px;
      height: 25px;
      width: 25px;
    }
  }

  &.item-checkbox-checked {
    .checkbox-checked {
      background-color: var(--colors-radio_checkbox_color);
      border-color: var(--color-radio_checkbox_color);

      .checkbox-inner {
        border-right-width: 3px;
        border-bottom-width: 3px;
        height: 14px;
        left: 8px;
        top: 3px;
        width: 8px;
      }
    }
  }
}

.tc { text-align: center; }
.tl { text-align: left; }
.tr { text-align: right; }
.fl { float: left; }
.fr { float: right; }

.is_plan {
  display: table;
  padding-left: 15px;
  padding-right: 15px;
}
.is_plan-icon {
  display: table-cell;
}
.is_plan-info {
  float: left;
  margin-left: 10px;
}
.text-captilize { text-transform: capitalize; }

.not-available { color: var(--color-danger)}

.danger-block {
  color: var(--color-danger);
  text-align: center;
}

.cancellation-fee {
  color: var(--color-danger);
}

.tidy-select {
  background: white;
  margin: 0 auto;
  width: 50%;

  ion-item.item {
    border: 1px solid var(--color-tidy_black);
    border-radius: 4px;
    background: white;

    ion-select {
      border: 0 none;
      margin: 0;
      max-width: 100%;
      padding: 13px 8px 13px 16px;
      width: 95%;
    }
  }
}

.week-to-display {
  ion-list {
    > ion-label {
      font-size: 1.6rem;
      margin: 20px 0 15px;
      text-align: center;
    }
  }
}

.tidy-alert,
.card-ios.tidy-alert,
.card-md.tidy-alert {
  background-color: var(--color-alert);
  color: var(--color-text);
  font-size: 1.6rem;
  margin: 10px 0 0;
  padding: 10px;
  text-align: center;

  &.info {
    background-color: var(--color-tidy_white);
    font-size: 1.4rem;
    text-align: left;
    strong {
      display: block;
    }
  }
}

.large_icon {
  width: 60px;
  height: 60px;
  padding: 10px 10px;
  border-radius: 35px;
  text-align: center;
  line-height: 1.428571429;
  border: 1px solid #000000;
  background: #FFF;
}

.navbar_icon {
  width: 25px;
  height: 25px;
  border-radius: 15px;
  padding: 5px;
  text-align: center;
  border: 0px;
  background: #FFF;
  margin: 0 10px 0 10px;
}

.tidy-alert {
  background-color: var(--color-alert);
  box-shadow: none;
  font-size: 1.6rem;
  margin: 12px 0 12px;
  width: 100%;
}

.cleaning {
  clear: both;
  .flex {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #ccc;
    &:last-child { border-bottom: none; }
  }

  .flex-item {
    flex: 1;
    padding: 15px 10px 14px 10px;
  }

  .cleaning-type-icon {
    width: 56px;
    img { max-width: 100%; margin-top: 4px; }
  }

  .cleaning-type {
    // font-family: $title;
  }

  .client {
    flex: 2
  }

  .actions {
    flex: 0 0 24px;
  }
}

.center-top {
  padding-top: 40%;
}

.center-top-30 {
  padding-top: 30%;
}

.alert-navbar div {
  background-color: var(--color-alert);
  border: 0;
  .toolbar-title.toolbar-title-md, .toolbar-title.toolbar-title-ios {
    color: black;
  }
}

.success-navbar div {
  background-color: var(--colors-tidy_green);
  border: 0;
}
.item-text-wrap{
  text-align: left;
}
.info-icon {
  display: block;
  margin: 20px auto;
}
.bigger-text{
  font-size: 20px;
}

.underlined {
  text-decoration: underline;
}

@media screen and (max-width: $break-large) {
  ion-split-pane {
    background-color: var(--ion-color-header_black);
  }
  .background-app-image {
    display: none;
  }
}

@media screen and (min-width: $break-large) {
  .background-app-image {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }
}

.tidy-link {
  cursor: pointer;
  color: var(--ion-color-tidy_green);
  text-decoration: underline;
}

.toolbar-title-ios {
  position: fixed;
  top: 0px;
  width: 100%;
}

.short-underline:after {
  border-bottom: 1px solid var(--color-title_border);
  content: "";
  display: block;
  margin: 5px auto 0;
  width: 80%;
}


.white-background {
  background: white;
}

.ion-color-tidy_blue {
  background: var(--ion-color-tidy_blue);
    &:hover{
      background: var(--ion-color-tidy_blue);
    }
  }

  .ion-color-tidy_yellow {
    background: var(--ion-color-tidy_yellow);
    &:hover{
      background: var(--ion-color-tidy_yellow);
    }
  }

  .ion-color-tidy_green {
    background: var(--ion-color-tidy_green);
    &:hover{
      background: var(--ion-color-tidy_green);
    }
  }

  .ion-color-tidy_light_green {
    background: #D3EEE9;
    &:hover{
      background: #D3EEE9;
    }
  }

  .ion-color-tidy_black {
    background: var(--ion-color-tidy_black);
    color: #fff;
    &:hover{
      background: var(--ion-color-tidy_black);
    }
  }

  .ion-color-facebook {
    background: var(--ion-color-facebook);
    color: #fff;
    &:hover{
      background: var(--ion-color-facebook);
    }
  }

  .toast-error {
    color: white;
    --background: #c30808;
  }

  .toast-success {
    color: white;
    --background: #2ecc71;
  }

  .compliance-alert {
    h2 {
      text-transform: none !important;
    }
  }

  .toast-info {
    color: white;
    --background: #416fca;
  }

  .attachment-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .attachment-image {
    width: 135px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s;
  }

  .attachment-image:hover {
    transform: scale(1.05);
  }

  .vertical-align-center, .vertical-align-center div{
    display: flex;
    align-items: center;
    white-space: pre-wrap;
    &.wrappable {
      tidy-image {
        align-self: baseline;
      }
    }
    &[ng-reflect-align="center"], &[align="center"] {
      justify-content: center;
    }
  }

$colors: (
  primary: #41cab7,
  secondary: #525659,
  danger: #f53d3d,
  light: #f4f4f4,
  light_green: #eff9f7,
  text: #434343,
  dark: #222,
  black: #000,
  header_black: #212121,
  favorite: #69bb7b,
  ivory: #fcfcfa,
  caption: #a7b0b5,
  tidy_background: #fcf7f0,
  tidy_green: #41cab7,
  tidy_black: #434343,
  tidy_plus: #49c9b7,
  tidy_star: #e1be5a,
  tidy_accept_cleaning: #404040,
  caption_dark: #8d8d8d,
  disabled: #e7e7e6,
  link: #2cafc8,
  mighty: #51adc6,
  default_border: #bebfc0,
  light_border: #dedede,
  title_border: #bfbcb8,
  alert: #fef9b7,
  notes: rgba(red, 0.5),
  radio_checkbox_color: #525659,
  no_cleanings_background: #ddd,
  no_cleanings_text: #777,
  slider_background: #b1e9e1,
  main_text: #434343,
  tidy_red: #ff6c78,
  tidy_black_secondary: #525659,
  tidy_full_black: black,
  tidy_white: #ffffff,
  alert_yellow: #fff9b7,
  caption_grey: #8d8d8d,
  unclickable_grey: #e7e7e6,
  more_blue: #2cafc8,
  background_ivory: #fcf7f0,
  box_input: rgba(255, 255, 255, 0.92),
  auto_complete_border: #dedede,
  selected_item: #e3fff9,
  facebook: #3b5998,
  light_color: #666
);

$title-font: 700 24px Neuton, serif;
$secondary-font: Neuton, serif;
$break-large: 992px;

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #0cd1e8;
  --ion-color-secondary-rgb: 12, 209, 232;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #0bb8cc;
  --ion-color-secondary-tint: #24d6ea;

  /** tertiary **/
  --ion-color-tertiary: #7044ff;
  --ion-color-tertiary-rgb: 112, 68, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #633ce0;
  --ion-color-tertiary-tint: #7e57ff;

  /** success **/
  --ion-color-success: #10dc60;
  --ion-color-success-rgb: 16, 220, 96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #0ec254;
  --ion-color-success-tint: #28e070;

  /** warning **/
  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255, 206, 0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255, 255, 255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 34, 34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;
  --ion-color-header_black: #212121;

  /** medium **/
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 244, 244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-favorite: #69BB7B;
  --ion-color-ivory: #FCFCFA;
  --ion-color-caption: #A7B0B5;
  --ion-color-tidy_background: #FCF7F0;
  --ion-color-tidy_green: #41CAb7;
  --ion-color-tidy_black: #434343;
  --ion-color-tidy_black_alpha: rgba(67,67,67, 0.6);
  --ion-color-caption_dark: #8D8D8D;
  --ion-color-disabled: #E7E7E6;
  --ion-color-link: #2CAFC8;
  --ion-color-link_secondary: #4EACC5;
  --ion-color-mighty: #51ADC6;
  --ion-color-default_border: #BEBFC0;
  --ion-color-light_border: #DEDEDE;
  --ion-color-alert: #fef9b7;
  --ion-color-alert_success: #dff0d8;
  --ion-color-notes: rgba(red, 0.5);
  --ion-color-radio_checkbox_color: #525659;
  --ion-color-radio_group_grid_label: black;
  --ion-color-box_input: rgba(255, 255, 255, 0.92);
  --ion-color-input_color: #7f7f7f;
  --ion-color-btn_login: #F6F5EA;
  --ion-color-swiper_pagination: #FFF;
  --ion-color-swiper_pagination_active: #000;
  --ion-color-auto_complete_border: #dedede;
  --ion-color-selected_item: #e3fff9;
  --ion-color-selected_negative: #FFE8E8;
  --ion-color-transparent: transparent;
  --ion-color-tidy_yellow: #FECD4E;
  --ion-color-tidy_blue: #2BAFC8;
  --ion-color-todos_tidy_green: #41CAB7;
  --ion-color-selected_form: #00AABA;
  --ion-color-bg_transparent: rgba(251, 251, 251, 0.85);
  --ion-color-title_description: #989898;
  --ion-color-regular_text: #333;
  --ion-color-facebook: #3b5998;

  // each item in color map
  @each $name, $color in $colors {
    --color-#{"" + $name}: $color;
  }

  --break-large: 992px;

  --grid-columns: 12;

  --ion-color-bg_transparent: rgba(251, 251, 251, 0.85);
  --toolbar-background: #000;
  --title: Georgia, Times, "Times New Roman", serif;
  --text-input-md-hightlight-color-valid: transparent;
  --helvetica-neue-light: "Helvetica Neue Light", "Helvetica Neue";
  --helvetica-neue: "Helvetica Neue";
  --saint-andrews-queen: "saint-andrews-queen";
  --background-secondary-color-desktop: #EFEBE4;

  --ion_title: "Helvetica Neue", Helvetica, Arial, sans-serif;
  --card_header: Georgia, Times, "Times New Roman", serif;

  --transparent-high: rgba(196,196,196,.1);
}
